<template>
  <v-dialog
    transition="scale-transition"
    :max-width="500"
    persistent
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-card>
      <o-form
        ref="form"
        @submit="submit"
        @close="$emit('input', false)"
      >
        <v-card-title class="primary--text d-flex justify-space-between">
          <span>{{ $t('messages.views.admin.components.configuration.stations.window.stationWindow.counterFormModal.title.' + mode) }}</span>
          <v-btn
            icon
            @click="close"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col>
              <o-select
                v-model="form.type"
                :items="getCounterTypes()"
                :rules="validation.type"
                label="labels.counter.type"
                prepend-icon="mdi-database-cog"
                dense
              >
                <template v-slot:item="props">
                  {{ $t('constants.counter.type.' + props.item) }}
                </template>
                <template v-slot:selection="props">
                  {{ $t('constants.counter.type.' + props.item) }}
                </template>
              </o-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <o-select
                v-model="form.sensors"
                :items="sensors"
                :rules="validation.sensors"
                label="labels.counter.sensors"
                prepend-icon="mdi-radar"
                dense
                multiple
                chips
                item-value="id"
                item-text="name"
              />
            </v-col>
          </v-row>
          <v-row v-if="customErrors.length">
            <v-col>
              <v-alert
                v-for="(error, i) in customErrors"
                :key="`error-${i}`"
                type="error"
              >
                {{ error }}
              </v-alert>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <o-submit v-if="!customErrors.length" />
            </v-col>
          </v-row>
        </v-card-text>
      </o-form>
    </v-card>
  </v-dialog>
</template>

<script>
  import { hydrate } from '@utils/form';
  import { getCounterTypes } from '@utils/counter';

  export default {
    props: {
      counter: {
        type: Object,
        default: null,
      },
      sensors: {
        type: Array,
        required: true,
      },
      usedNumbers: {
        type: Array,
        default: () => ([]),
      },
    },

    data: function () {
      return {
        form: {
          type: '',
          sensors: [],
        },
        validation: require('@/validation/entities/counter.json'),
        mode: 'create',
      };
    },

    computed: {
      customErrors () {
        const errors = [];

        const maxSensors = this.maxSensorsHandledByCounterType;

        if (maxSensors !== null && this.form.sensors.length > maxSensors) {
          errors.push(this.$tc(
            'messages.views.admin.components.configuration.stations.window.stationWindow.counterFormModal.errors.max',
            maxSensors,
            { max: maxSensors }
          ));
        }

        return errors;
      },
      maxSensorsHandledByCounterType () {
        switch (this.form.type) {
          case 'TYPE_LANE_COUNTING':
            return 9;
          case 'TYPE_OCCUPANCY':
            return 1;
        }

        return null;
      },
    },

    created () {
      if (this.counter !== null) {
        hydrate(this.form, this.counter);
        if (undefined !== this.counter.id && this.counter.id !== null) {
          this.mode = 'update';
        }
      }
    },

    methods: {
      submit (scope) {
        this.$emit('submit', this.form);
      },
      close () {
        this.$refs.form.close();
      },
      getCounterTypes () {
        return getCounterTypes();
      },
    },
  };
</script>
